import React, { FC, useState } from 'react'
import NextLink from 'next/link'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    Avatar,
    Box,
    Button,
    Center,
    Divider,
    FormControl,
    FormErrorMessage,
    Grid,
    GridItem,
    IconButton,
    Input,
    Link,
    Select,
    Text,
    useToast,
} from '@chakra-ui/react'

import { EditLine } from '../icons'
import { colors } from '@/utils/const'
import { User } from '@/interfaces'
import { useUser } from '@/hooks/users'

interface Props {
    user: User
}

const Schema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    phone: Yup.string()
        .min(10, 'Mínimo 10 caracteres')
        .max(14, 'Maximo 14 caracteres'),
    email: Yup.string()
        .email('Correo invalido')
        .required('El correo es requerido'),
    address: Yup.string(),
    alias: Yup.string(),
})

export const UserDetails: FC<Props> = ({ user }) => {
    const { updateUser } = useUser({ user_id: user?.id })
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isEditingStatus, setIsEditingStatus] = useState(false)
    const [isLoadingStatus, setIsLoadingStatus] = useState(false)
    const [status, setStatus] = useState('active')
    const toast = useToast()

    const formik = useFormik({
        initialValues: {
            name: user?.name || '',
            email: user?.email || '',
            phone: user?.phone || '',
            alias: user?.alias || '',
            address: user?.address || '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            setIsLoading(true)

            try {
                await updateUser({
                    user_id: user?.id,
                    data: {
                        name: values.name,
                        email: values.email,
                        phone: values.phone ? `+${values.phone}` : null,
                        address: values.address,
                        alias: values.alias,
                    },
                })

                toast({
                    title: 'Éxito',
                    description: 'Usuario actualizado con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

                setIsEditing(false)
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }

            setIsLoading(false)
        },
    })

    const handleStatus = async () => {
        setIsLoadingStatus(true)

        try {
            await updateUser({
                user_id: user?.id,
                data: {
                    status,
                },
            })

            toast({
                title: 'Éxito',
                description: 'Usuario actualizado con éxito',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })

            setIsEditingStatus(false)
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIsLoadingStatus(false)
    }

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg">
            <form onSubmit={formik.handleSubmit}>
                <Grid p={4}>
                    {!isEditing ? (
                        <>
                            <Grid
                                templateColumns="repeat(8, 1fr)"
                                gap={2}
                                fontSize={12}
                                fontWeight={400}
                                color={colors['gray-primary']}>
                                <GridItem w="100%">
                                    <Avatar size={'md'} />
                                </GridItem>
                                <GridItem colSpan={2} w="100%">
                                    <Text mb={1}>Usuario</Text>
                                    <Text>{user?.name}</Text>
                                </GridItem>
                                <GridItem colSpan={3} w="100%">
                                    <Text mb={1}>Email</Text>
                                    <Text>{user?.email}</Text>
                                </GridItem>
                                <GridItem colSpan={2} w="100%">
                                    <Text mb={1}>Teléfono</Text>
                                    <Text>{user?.phone}</Text>
                                </GridItem>
                                <GridItem w="100%" gridColumn={'end'}>
                                    <IconButton
                                        aria-label={''}
                                        bg="transparent"
                                        size="xs"
                                        icon={
                                            <EditLine
                                                color={colors['black-soft']}
                                            />
                                        }
                                        onClick={() => setIsEditing(!isEditing)}
                                        _active={{}}
                                        _hover={{}}
                                    />
                                </GridItem>
                            </Grid>
                            <Grid
                                templateColumns="repeat(6, 1fr)"
                                gap={1}
                                fontSize={12}
                                fontWeight={400}
                                mt={6}>
                                <GridItem>
                                    <Text>Domicilio:</Text>
                                </GridItem>
                                <GridItem colSpan={4}>
                                    <Text>{user?.address}</Text>
                                </GridItem>
                            </Grid>
                        </>
                    ) : (
                        <Box
                            display="flex"
                            justifyContent="center"
                            fontSize={12}
                            fontWeight={400}
                            color={colors['gray-primary']}>
                            <Box w="60%">
                                <Text mb={1}>Usuario</Text>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.name &&
                                        formik.touched.name
                                    }>
                                    <Input
                                        color="black"
                                        id="name"
                                        placeholder="Nombre"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.name}
                                    </FormErrorMessage>
                                </FormControl>

                                <Text mb={1}>Email</Text>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.email &&
                                        formik.touched.email
                                    }>
                                    <Input
                                        color="black"
                                        id="email"
                                        placeholder="Email"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.email}
                                    </FormErrorMessage>
                                </FormControl>

                                <Text mb={1}>Teléfono</Text>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.phone &&
                                        formik.touched.phone
                                    }>
                                    <PhoneInput
                                        inputStyle={{
                                            height: '25px',
                                            width: '100%',
                                        }}
                                        country={'es'}
                                        value={formik.values.phone}
                                        onChange={phone =>
                                            formik.setFieldValue('phone', phone)
                                        }
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.phone}
                                    </FormErrorMessage>
                                </FormControl>

                                <Text mb={1}>Domicilio:</Text>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.address &&
                                        formik.touched.address
                                    }>
                                    <Input
                                        color="black"
                                        id="address"
                                        placeholder="Dirección"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.address}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.address}
                                    </FormErrorMessage>
                                </FormControl>

                                <Text mb={1}>Alias:</Text>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.alias &&
                                        formik.touched.alias
                                    }>
                                    <Input
                                        color="black"
                                        id="alias"
                                        placeholder="Alias"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.alias}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.alias}
                                    </FormErrorMessage>
                                </FormControl>

                                <Center>
                                    <Button
                                        bg={colors.tertiary}
                                        border="1px"
                                        color="white"
                                        fontSize={12}
                                        fontWeight={500}
                                        h="24px"
                                        isLoading={isLoading}
                                        type="submit"
                                        w={84}
                                        _active={{
                                            transform: 'scale(0.98)',
                                        }}
                                        _hover={{}}>
                                        Guardar
                                    </Button>
                                </Center>
                            </Box>

                            <IconButton
                                aria-label={''}
                                ml={2}
                                bg="transparent"
                                size="xs"
                                icon={<EditLine color={colors['black-soft']} />}
                                onClick={() => setIsEditing(!isEditing)}
                                _active={{}}
                                _hover={{}}
                            />
                        </Box>
                    )}
                </Grid>
                <Divider border={1} />
                <Grid
                    p={4}
                    templateColumns="repeat(2, 1fr)"
                    gap={2}
                    fontSize={12}
                    fontWeight={400}>
                    <GridItem w="100%">
                        {isEditingStatus ? (
                            <Box w="60%">
                                <Text mb={1}>Estado:</Text>
                                <Select
                                    id="status"
                                    size="xs"
                                    mb={2}
                                    rounded={6}
                                    defaultValue={user?.status || 'active'}
                                    onChange={e => setStatus(e.target.value)}>
                                    <option value="active">Activo</option>
                                    <option value="inactive">Inactivo</option>
                                </Select>

                                <Center>
                                    <Button
                                        bg={colors.tertiary}
                                        border="1px"
                                        color="white"
                                        fontSize={12}
                                        fontWeight={500}
                                        h="24px"
                                        isLoading={isLoadingStatus}
                                        w={84}
                                        onClick={handleStatus}
                                        _active={{
                                            transform: 'scale(0.98)',
                                        }}
                                        _hover={{}}>
                                        Guardar
                                    </Button>
                                </Center>
                            </Box>
                        ) : (
                            <>
                                <Text mb={2}>Estado</Text>
                                <Text
                                    mb={3}
                                    fontSize={14}
                                    fontWeight={700}
                                    color={
                                        user?.status === 'active'
                                            ? colors.tertiary
                                            : '#FD7C04'
                                    }>
                                    {user?.status === 'active'
                                        ? 'Activo'
                                        : 'Inactivo'}
                                </Text>
                            </>
                        )}

                        <Text mb={2}>Rol</Text>
                        <Text fontWeight={700} color={colors.black}>
                            {user?.roles[0]?.description}
                        </Text>
                    </GridItem>
                    <GridItem w="100%">
                        <Grid templateColumns="5fr 1fr" gap={2}>
                            <GridItem w="100%">
                                <Text>Fecha de creación</Text>
                                <Text mt={2} fontSize={14}>
                                    {new Date(
                                        user?.created_at,
                                    ).toLocaleDateString('es-ES', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                        minute: '2-digit',
                                        hour: '2-digit',
                                    })}
                                </Text>
                            </GridItem>
                            <GridItem w="100%" gridColumn={'end'}>
                                <IconButton
                                    aria-label={''}
                                    bg="transparent"
                                    size="xs"
                                    icon={
                                        <EditLine
                                            color={colors['black-soft']}
                                        />
                                    }
                                    onClick={() =>
                                        setIsEditingStatus(!isEditingStatus)
                                    }
                                    _active={{}}
                                    _hover={{}}
                                />
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
                <Divider border={1} />
                <Grid
                    p={4}
                    templateColumns="repeat(2, 1fr)"
                    gap={2}
                    fontSize={10}>
                    <GridItem w="100%">
                        <Text>Creado</Text>
                        <Text mt={2} fontWeight={500}>
                            {new Date(user?.created_at).toLocaleDateString(
                                'es-ES',
                                {
                                    day: '2-digit',
                                    month: 'numeric',
                                    year: 'numeric',
                                    minute: '2-digit',
                                    hour: '2-digit',
                                },
                            )}
                        </Text>
                    </GridItem>
                    <GridItem w="100%">
                        <Text>Última modificación</Text>
                        <Text mt={2} fontWeight={500}>
                            {new Date(user?.updated_at).toLocaleDateString(
                                'es-ES',
                                {
                                    day: '2-digit',
                                    month: 'numeric',
                                    year: 'numeric',
                                    minute: '2-digit',
                                    hour: '2-digit',
                                },
                            )}
                        </Text>
                    </GridItem>
                </Grid>
            </form>
        </Box>
    )
}
